* {
  box-sizing: border-box;
}

/* body {
  background-image: linear-gradient(135deg, #f5f7fa 0%);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  min-height: 100vh;
  margin: 0;
} */

#root {
  width: 100%;
}

h1 {
  text-align: center ;
}

.timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.timeline-container:after {
  background-color: rgba(255, 255, 255, 0.2);
  content: '';
  align-self: center;
  position: absolute;
  width: 4px;
  height: 70%;
  height  : 450px;
  left: calc(50%-2px);
}

.timeline-item {
/*     background-color: purple;
*/    display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  width: 50%;
}

.timeline-item:nth-child(odd) {
  align-self: flex-end;
  justify-content: flex-start;
  padding-right: 0px;
  padding-left: 30px;
}

.timeline-item-content {
  background-color: #454545;
  border-radius: 5px;
  box-shadow: 0 0 5px (0,0,0,0.3);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position:relative;
  padding: 10px;
  max-width: 90%;
  width: 35 0px;
  text-align: right;
}

.timeline-item-content::after {
  content: '';
  background-color: #454545;
  box-shadow: 1px -1px 1px rgba(0,0,0,0.2);
  width: 15px;
  height: 15px;
  right: -7.5px;
  top: calc(50% - 7.5px);
  transform: rotate(45deg);
  position: absolute;
}

.timeline-item:nth-child(odd)  .timeline-item-content {
  align-items: flex-start;
  text-align: left;
}

.timeline-item:nth-child(odd)  .timeline-item-content::after {
  right: auto;
  box-shadow: -1px 1px 1px rgba(0,0,0,0.2);
  left: -7.5px;

}


/* .ciaociao {
background-image: url(https://firebasestorage.googleapis.com/v0/b/leonardsuteraweb.appspot.com/o/sfondoppt.gif?alt=media&token=a411ada9-f892-4734-ad34-9549c7de088f) ;
background-attachment: fixed;
background-repeat: no-repeat;
height: 100%;
background-size: cover;
} */

.ciaociao {
  background-color: #121212;
}

.timeline-item-content .circle {
  background-color: #c2c2c2;
  border: 3px solid #150b70;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 10px);
  right: -40px;
  width: 20px;
  height: 20px;
  z-index: 5;
}

.timeline-item:nth-child(odd)  .timeline-item-content .circle {
  right: auto;
  left: -40px;

}

@media (max-width: 768px) {
  .timeline-container:after {
      background-color: rgba(255, 255, 255, 0.2);
      content: '';
      align-self: center;
      position: absolute;
      width: 4px;
      height: calc(550px - 8vh);
      left: calc(50%-2px);
  }
}

* {
  box-sizing: border-box;
}


#root {
  width: 100%;
}

h1 {
  text-align: center ;
}






.card-item-content {
  background-color: #454545;
  border-radius: 10px;
  box-shadow: 0 0 5px (0,0,0,0.3);
  text-align: left;
  flex: 1 1 100px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  margin-right: 10%;
  margin-left: 10%;
  margin-bottom: 10%;
  transition: transform .2s; /* Animation */
}

.card-item-content:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.theCard{
  width: 300px!important;
}



.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 100;
  top:0;
  left: 0;
}

.title{
  display: flex;
}

.icon a{
  color:#c2c2c2;
  text-decoration: none;
}

.toolbar_navigation-item a {
  color:white;
  text-decoration: none;
}

.icon:hover,
.icon:active {
color:#b6a59a;
}

.proportionalMargin{
  margin-inline: 15%
  ;
}

.mb100px{
  margin-bottom: 100px;
}

.posCard {
/*   border-radius: 10px;
  margin-inline: 10%;
  height: 300px;
  width: 360px; */
  width: 40%;
  border-radius: 20px;
}

.text{
  margin-top:20px;
  font-size:15px;
  font-family:Verdana;
  color:rgba(194,194,194,0.7);
  margin-bottom: 20px;
  width: 60%;
}

.textTitle{
  font-size:15px;
  font-family:Verdana;
  color:rgba(194,194,194,0.7);
  margin-bottom: 10px;
  font-weight: bold;
  width: 60%;
}

.spanContacts{
  height: 100%;
  list-style: none;
  flex-direction: row;
  display: list-item;
  padding-inline: 15px;
  display: flex;
  
}

.wewe {
  display: flex;
  justify-content: space-evenly;
  margin-inline: 7%;
}



.span{
  display: flex;
  flex-direction: row;
  align-items: center;
  
}

.spanAbout{
  display: flex;
  flex-direction: row;
  align-items: center;
  
}

.spanPosEven{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 60px;
  justify-content: space-between;
}

.spanPosOdd{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 60px;
  justify-content: space-between;
}

.spantitle{
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-bottom: 10px;
}

.spanelement{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2px;
}

.span3{
  display: flex;
  flex-direction: row;
}

.imageapple {
  border-radius: 10px;
  margin-inline: 10%;
}

.appleimage {
  border-radius: 10px;
  margin-inline: 10%;
  height: 220px;
  width: 260px;
}




.princlang{
  display: flex;
  color: #c2c2c2;
}














.cont {
  display: flex;
  align-items: center;
  justify-content: center;    

}

.posPage{
  background-color: #050505;
  background-image:  url(https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/posdigitalbackground.jpg?alt=media&token=bc4f8823-e44e-4cda-95a1-6ed84c19d667);
  height: 2000px;
  background-repeat: no-repeat;
  background-size: auto;
}



.trapezioPos {
  border-bottom: 28vw solid rgba(10, 50, 50, 0.4);
    background-image: url(https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/poshome.jpg?alt=media&token=475c9680-6fe8-428e-8415-1dfbda4fa089);
    clip-path: polygon(0 0, 49% 0, 100% 100%, 0% 100%); 
 border-left: 0px solid transparent;
 background-size: 60vw;
 background-repeat: no-repeat;
 position: relative ;
  border-right: 150px solid transparent;
  z-index: 2; /* imposta lo z-index in modo che il triangolo sia sopra il trapezio */
  width: 60%; 
  right: 15%;
  height: 150%;
  transition: border-bottom;
}

.trapezioGen {
  border-bottom: 28vw solid rgba(10, 10, 10, 0.5);
    background-image: url(https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/gensite.jpg?alt=media&token=7a3bccdc-c399-4fe5-8d8b-2f65e46a3b7a);
    clip-path: polygon(0 0, 49% 0, 100% 100%, 0% 100%); 
 border-left: 0px solid transparent;
 background-size: 40vw;
 background-repeat: no-repeat;
 position: relative ;
  border-right: 150px solid transparent;
  z-index: 2; /* imposta lo z-index in modo che il triangolo sia sopra il trapezio */
  width: 60%; 
  right: 15%;
  height: 150%;
  transition: border-bottom;
}

.trapezioPos:hover {
  transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  border-bottom: 28vw solid transparent;
      background-image: url(https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/poshome.jpg?alt=media&token=475c9680-6fe8-428e-8415-1dfbda4fa089);
      clip-path: polygon(0 0, 49% 0, 100% 100%, 0% 100%); 
   border-left: 0px solid transparent;
   background-size: 60vw;
   background-repeat: no-repeat;
   position: relative ;
    border-right: 150px solid transparent;
    z-index: 2; /* imposta lo z-index in modo che il triangolo sia sopra il trapezio */
    width: 60%; 
    right: 15%;
    height: 150%;
}

.trapezioGen:hover {
  transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  border-bottom: 28vw solid transparent;
      background-image: url(https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/gensite.jpg?alt=media&token=7a3bccdc-c399-4fe5-8d8b-2f65e46a3b7a);
      clip-path: polygon(0 0, 49% 0, 100% 100%, 0% 100%); 
   border-left: 0px solid transparent;
   background-size: 40vw;
   background-repeat: no-repeat;
   position: relative ;
    border-right: 150px solid transparent;
    z-index: 2; /* imposta lo z-index in modo che il triangolo sia sopra il trapezio */
    width: 60%; 
    right: 15%;
    height: 150%;

}



.triangolo {
  margin-top: 2%;
  border-bottom: 35vw solid #232323;
  clip-path: polygon(0 10%, 100% 10%, 100% 86%, 30% 85%);
  border-left: 0px solid transparent;
 background-size: 150%;
 background-repeat: no-repeat;
  border-right: 20px solid transparent;
  width: 40%; 
  position: absolute; /* permette di posizionare il triangolo rispetto al div del trapezio */
  left: 40%; /* posiziona il triangolo a metà del div del trapezio */
  /* altri stili... */

}

.trapezioPos, .triangolo {
  transition: transform .2s; /* Animation */
  display: inline-block;
  align-items: center;
  
}

.trapezioGen, .triangolo {
  transition: transform .2s; /* Animation */
  display: inline-block;
  align-items: center;
}

.PosTitle {
  color:#c2c2c2;
  left:50%;
  margin-top:14%;
  z-index:100;
  position:absolute;
  font-family:Newake;
  font-size:28px;
  
}

.certificationContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.GenTitle {
  color:#c2c2c2;
  left:50%;
  margin-top:14%;
  z-index:100;
  position:absolute;
  font-family:Newake;
  font-size:30px;
}

.PosDescription {
  color:#c2c2c2;
  left:40%;
  margin-top:22%;
  z-index:100;
  position:absolute;
  font-family:Verdana;
  font-size:22px;
}

.GenDescription {
  color:#c2c2c2;
  left:40%;
  margin-top:22%;
  z-index:100;
  position:absolute;
  font-family:Verdana;
  font-size:22px;
}


.firstimg {
  left:58%;
  margin-top:100%;
  z-index:100;
  position:absolute;
  width: 9%;
}

.firsttext {
  color:#c2c2c2;
  font-size:11px;
  font-family:Verdana;
  position:absolute;
  font-weight:bold;
  z-index:100;
  left:68%;
  margin-top:100%;
}

.secondimg {
  left:80%;
  margin-top:100%;
  z-index:100;
  position:absolute;
  width: 10%;
}

.secondimggen {
  left:80%;
  margin-top:100%;
  z-index:100;
  position:absolute;
  width: 8%;
}

.secondtext {
  color:#c2c2c2;
  font-size:11px;
  font-family:Verdana;
  position:absolute;
  font-weight:bold;
  z-index:100;
  left:89%;
  margin-top:100%;
}

.thirdimg {
  left:59%;
  margin-top:125%;
  z-index:100;
  position:absolute;
  width: 7%;
}

.thirdimggen {
  left:57%;
  margin-top:125%;
  z-index:100;
  position:absolute;
  width: 11%;
}

.thirdtext {
  color:#c2c2c2;
  font-size:11px;
  font-family:Verdana;
  position:absolute;
  font-weight:bold;
  z-index:100;
  left:68%;
  margin-top:125%;
}

.fourthimg {
  left:80%;
  margin-top:125%;
  z-index:100;
  position:absolute;
  width: 10%;
}

.fourthtext {
  color:#c2c2c2;
  font-size:11px;
  font-family:Verdana;
  position:absolute;
  font-weight:bold;
  z-index:100;
  left:90%;
  margin-top:125%;
}

.mailStyle {
  height : 50px;
  width: 50px;
  margin-right: 20px;
  transition: all 0.2s ease; /* transizione smooth di 0.3 secondi per tutti i cambiamenti */
}



.mailStyle:hover {
  transform: scale(1.20); 
}






 @media screen and (max-width: 768px) {

  .mb100px{
    margin-bottom: 50px;
  }

    .trapezioPos {
      border-bottom: 50vw solid rgba(10, 50, 50, 0.4);
        background-image: url(https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/poshome.jpg?alt=media&token=475c9680-6fe8-428e-8415-1dfbda4fa089);
        clip-path: polygon(0 0, 30% 0, 100% 100%, 0% 100%); 
     border-left: 0px solid transparent;
     background-size: 120vw;
     background-repeat: no-repeat;
     position: relative ;
      border-right: 150px solid transparent;
      z-index: 2; /* imposta lo z-index in modo che il triangolo sia sopra il trapezio */
      width: 60%; 
      right: 15%;
      height: 150%;
      transition: border-bottom;
    }

    .trapezioPos:hover {
      transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
      border-bottom: 50vw solid transparent;
          background-image: url(https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/poshome.jpg?alt=media&token=475c9680-6fe8-428e-8415-1dfbda4fa089);
          clip-path: polygon(0 0, 30% 0, 100% 100%, 0% 100%); 
       border-left: 0px solid transparent;
       background-size: 120vw;
       background-repeat: no-repeat;
       position: relative ;
        border-right: 150px solid transparent;
        z-index: 2; /* imposta lo z-index in modo che il triangolo sia sopra il trapezio */
        width: 60%; 
        right: 15%;
        height: 150%;
    }

    .trapezioPos, .triangolo {
      transition: transform .2s; /* Animation */
      display: inline-block;
      align-items: center;
    }

    .PosTitle {
      color:#c2c2c2;
      left:50%;
      margin-top:13%;
      z-index:100;
      position:absolute;
      font-family:Newake;
      font-size:28px;
    }

    .secondimggen {
      left:90%;
      margin-top:110%;
      z-index:100;
      position:absolute;
      width: 8%;
    }


  

    .trapezioGen {
      border-bottom: 50vw solid rgba(10, 10, 10, 0.5);
      background-image: url(https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/gensite.jpg?alt=media&token=7a3bccdc-c399-4fe5-8d8b-2f65e46a3b7a);
      clip-path: polygon(0 0, 30% 0, 100% 100%, 0% 100%); 
   border-left: 0px solid transparent;
   background-size: 60vw;
   background-repeat: no-repeat;
   position: relative ;
    border-right: 150px solid transparent;
    z-index: 2; /* imposta lo z-index in modo che il triangolo sia sopra il trapezio */
    width: 60%; 
    right: 15%;
    height: 150%;
    transition: border-bottom;
}

.trapezioGen:hover {
  transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  border-bottom: 50vw solid transparent;
      background-image: url(https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/gensite.jpg?alt=media&token=7a3bccdc-c399-4fe5-8d8b-2f65e46a3b7a);
      clip-path: polygon(0 0, 30% 0, 100% 100%, 0% 100%); 
   border-left: 0px solid transparent;
   background-size: 60vw;
   background-repeat: no-repeat;
   position: relative ;
    border-right: 150px solid transparent;
    z-index: 2; /* imposta lo z-index in modo che il triangolo sia sopra il trapezio */
    width: 60%; 
    right: 15%;
    height: 150%;
}

.trapezioGen, .triangolo {
  transition: transform .2s; /* Animation */
  display: inline-block;
  align-items: center;
}

.GenTitle {
  color:#c2c2c2;
  left:40%;
  margin-top:12%;
  z-index:100;
  position:absolute;
  font-family:Newake;
  font-size:120%;
}


.GenDescription {
  color:#c2c2c2;
  left:50%;
  margin-top:20%;
  z-index:100;
  position:absolute;
  font-family:Verdana;
  font-size:100%;
}

      
.triangolo {
  border-bottom: 62vw solid #232323;
  clip-path: polygon(0 12%, 100% 12%, 100% 82%, 00% 82%);
  border-left: 0px solid transparent;
 background-size: 150%;
 background-repeat: no-repeat;
  border-right: 20px solid transparent;
  width: 70%; 
  position: absolute; 
  left: 20%; 
}


.firstimg {
  left:65%;
  margin-top:110%;
  z-index:100;
  position:absolute;
}

.firsttext {
  color:#c2c2c2;
  font-size:12px;
  font-family:Verdana;
  position:absolute;
  font-weight:bold;
  z-index:100;
  left:61%;
  margin-top:100%;
  display: none;
}

.secondimg {
  left:90%;
  margin-top:110%;
  z-index:100;
  position:absolute;
}

.secondtext {
  color:#c2c2c2;
  font-size:12px;
  font-family:Verdana;
  position:absolute;
  font-weight:bold;
  z-index:100;
  left:83%;
  margin-top:100%;
  display: none;
}

.thirdimg {
  left:66%;
  margin-top:135%;
  z-index:100;
  position:absolute;
}

.thirdimggen {
  left:64%;
  margin-top:135%;
  z-index:100;
  position:absolute;
}

.thirdtext {
  color:#c2c2c2;
  font-size:12px;
  font-family:Verdana;
  position:absolute;
  font-weight:bold;
  z-index:100;
  left:61%;
  margin-top:120%;
  display: none;
}

.fourthimg {
  left:90%;
  margin-top:135%;
  z-index:100;
  position:absolute;
}

.fourthtext {
  color:#c2c2c2;
  font-size:12px;
  font-family:Verdana;
  position:absolute;
  font-weight:bold;
  z-index:100;
  left:83%;
  margin-top:120%;
  display: none;
}


}

/*  @media screen and (max-width: 480px) {
  .trapezioPos {
    border-bottom: 40vw solid darkgreen;
    background-image: url(../../assets/poshome.jpg);
    border-left: 0px solid transparent;
    border-right: 150px solid transparent;
    height: 0;
    width: 50%;
  }

  .trapezioGen {
      border-bottom: 40vw solid darkgreen;
      background-image: url(../../assets/poshome.jpg);
      border-left: 0px solid transparent;
      border-right: 150px solid transparent;
      height: 0;
      width: 50%;
    }
} 
 */



 @media screen and (max-width: 1200px) {

  .appleimage {
    border-radius: 10px;
    margin-inline: 10%;
    height: 180px;
    width: 220px;
  }

  .PosDescription {
      color:#c2c2c2;
      left:42%;
      margin-top:28%;
      z-index:100;
      position:absolute;
      font-family:Verdana;
      font-size:15px;
    }

    .PosTitle {
      color:#c2c2c2;
      left:42%;
      margin-top: 19%;
      z-index:100;
      position:absolute;
      font-family:Newake;
      font-size:24px;
      
    }

    .GenDescription {
      color:#c2c2c2;
      left:42%;
      margin-top:28%;
      z-index:100;
      position:absolute;
      font-family:Verdana;
      font-size:14px;

    }

    .GenTitle {
      color:#c2c2c2;
      left:42%;
      margin-top: 19%;
      z-index:100;
      position:absolute;
      font-family:Newake;
      font-size:24px;
      
    }

    .firsttext {
      color:#c2c2c2;
      font-size:12px;
      font-family:Verdana;
      position:absolute;
      font-weight:bold;
      z-index:100;
      left:65%;
      margin-top:100%;
      display: none;
    }
 
  
    .secondtext {
      color:#c2c2c2;
      font-size:12px;
      font-family:Verdana;
      position:absolute;
      font-weight:bold;
      z-index:100;
      left:87%;
      margin-top:100%;
      display: none;

    }
  
  
    .thirdtext {
      color:#c2c2c2;
      font-size:12px;
      font-family:Verdana;
      position:absolute;
      font-weight:bold;
      z-index:100;
      left:65%;
      margin-top:120%;
      display: none;

    }
  
  
  
    .fourthtext {
      color:#c2c2c2;
      font-size:12px;
      font-family:Verdana;
      position:absolute;
      font-weight:bold;
      z-index:100;
      left:87%;
      margin-top:120%;
      display: none;

    }
 }



 @media screen and (max-width: 600px) {

.certificationContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.theCard{
  width: 80vw!important;
}

 .PosTitle {
  color:#c2c2c2;
  left:50%;
  margin-top:19%;
  z-index:100;
  position:absolute;
  font-family:Newake;
  font-size:24px;
}

.GenTitle {
  color:#c2c2c2;
  left: 45%;
  margin-top:19%;
  z-index:100;
  position:absolute;
  font-family:Newake;
  font-size:24px;
}

.GenDescription {
  color:#c2c2c2;
  left:45%;
  margin-top:29%;
  z-index:100;
  position:absolute;
  font-family:Verdana;
  font-size:15px!important;
}

.PosDescription {
  color:#c2c2c2;
  left:50%;
  margin-top:29%;
  z-index:100;
  position:absolute;
  font-family:Verdana;
  font-size:16px!important;
}

}




.side-drawer {
  height: 100%;
  background: #232323;
  box-shadow: 2px 0px 10px rgba(0,0,0,0.5);
  position: fixed;
  top:0;
  left:0;
  width: '70%';
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;

}

.side-drawer.open {
  transform: translateX(0);
}



.side-drawer ul {
  height: 100%;
  list-style: none;
  flex-direction: column;
  display: list-item;
  padding-inline: 15px;
  display: flex;
  margin-top: 30px;
  
}

.side-drawer li {
  margin: 0.5rem 0;
  margin-bottom: 30px;
}

.side-drawer a {
  color:#232323;
  text-decoration: none;
  font-size: 1.2rem;
}

.side-drawer a:hover,
.side-drawer a:active {
  color: #999999;
}

.side-drawer a:active {
  color: #999999;
}


@media (min-width: 769px) {
      .side-drawer{
          display: none;
      }
}

.toggle-button {
  display: flex;
  flex-direction: column;
  height: 24px;
  justify-content: space-around;
  width: 36px;
  background: transparent;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  }
  
  .toggle-button:focus {
  outline: none;
  }
  
  .toggle-button__line {
      width: 100%;
      height: 2px;
      background: #c2c2c2;
  }

.toolbar{
  width: 100%;
  position: fixed;
  background: #252525;
  height: 56px;
  top:0;
  left:0;
  animation: 1s ease-out 0s 1 slideTop;
  z-index: 10;

}

.toolbar_navigation{
display: flex;
height: 100%;
align-items: center;
padding: 0 1rem;
}

.toolbar__logo {
  margin-left: 1rem;
}

.toolbar__logo a {
  color:white;
  text-decoration: none;
  font-size: 1.5rem;
}

.spacer {
  flex:1;
}

.icon {
  color:#c2c2c2;
}


.toolbar_navigation-item a {
  color:white;
  text-decoration: none;
}

.toolbar_navigation-item ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation-item li {
  padding: 0 0.5rem
}

.toolbar_navigation-item a:hover,
.toolbar_navigation-item a:active {
  color: #999999;
}

.icon:hover,
.icon:active {
color:#999999;
}

@media (max-width: 600px) {

    .PosDescription {
        color:#c2c2c2;
        left:43%;
        margin-top:28%;
        z-index:100;
        position:absolute;
        font-family:Verdana;
        font-size:14px;
      }
  
      .PosTitle {
        color:#c2c2c2;
        left:43%;
        margin-top: 19%;
        z-index:100;
        position:absolute;
        font-family:Newake;
        font-size:22px;
        
      }
}

@media (max-width: 768px) {
  .toolbar_navigation-item {
      display: none;
  }
    .PosDescription {
        color:#c2c2c2;
        left:43%;
        margin-top:28%;
        z-index:100;
        position:absolute;
        font-family:Verdana;
        font-size:18px;
      }
  
      .PosTitle {
        color:#c2c2c2;
        left:43%;
        margin-top: 19%;
        z-index:100;
        position:absolute;
        font-family:Newake;
        font-size:22px;
        
      }

      .GenDescription {
        color:#c2c2c2;
        left:46%;
        margin-top:28%;
        z-index:100;
        position:absolute;
        font-family:Verdana;
        font-size:18px;
      }
  
      .GenTitle {
        color:#c2c2c2;
        left:46%;
        margin-top: 19%;
        z-index:100;
        position:absolute;
        font-family:Newake;
        font-size:25px;
        
      }
}


@media (min-width: 769px) {
  .toolbar__toggle-button {
      display: none;
  }

  .toolbar__logo {
      margin-left: 0;
  }

  

}

@keyframes slideTop {
  0% {
    transform: translateY(-100%);
    opacity: 1%;
  }
  50% {
    opacity: 25%;
  }
  100% {
    transform: translateY(0);
    opacity: 100%;
  }
}



.font-face-ModernSans {

    font-family: "Verdana";
    color: #b6a59a;
    font-size: 18px;
    font-weight: 100;
    animation: 2s fadeIn;
    animation-fill-mode: forwards;
    visibility: hidden;
        margin-top: 150px;
    margin-bottom: 20px;
  }

  .spanworkwork{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1%;
}

.imagereply {
    border-radius: 10px;
    margin-inline: 5%;
    margin-bottom: 3%;
    margin-top: 3%;
    width: 180px;
    aspect-ratio: 25 / 23;
}


.texttitlework{
    margin-top:20px;
    font-size: 23px;
    font-family:Newake;
    color:rgba(194,194,194,0.7);
}

.textdatework{
    margin-top:20px;
    font-size: 15px;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    color:rgba(194,194,194,0.7);
}

.couplework {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.textdescriptionwork{
    margin-top:20px;
    font-size:15px;
    font-family:Verdana;
    color:rgba(194,194,194,0.7);
}

.cardwork {
    background-color:#454545;
    border-radius:20px;
    width:100%;
    margin-top:20px;
    transition: transform .2s; /* Animation */
    box-shadow: 0px 5px 15px rgba(0,0,0,0.25);
}

.cardwork:hover {
    transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

@media (max-width: 768px) {
    .spanworkwork{
    
    }
    .texttitlework{
        margin-top:20px;
        font-size: 20px;
        font-family:Newake;
        color:rgba(194,194,194,0.7);
    }

    .textdescriptionwork{
        margin-top:20px;
        font-size:13px;
        font-family:Verdana;
        color:rgba(194,194,194,0.7);
    }
    
      
}

  
.font-face-nwk {

    font-family: "Newake";
    color: #c2c2c2;
    animation: 2s fadeIn;
    animation-fill-mode: forwards;
    visibility: hidden;

   }

.aboutAnimation {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.educationAnimation {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.inanimation1 {

  animation: 2s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.inanimation2 {
  animation: 2s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;

}


.underimg{
    position: absolute;
  top: 8px;
  right: 16px;
}

.main{
  margin-left: 20%;
  margin-right: 15%;
}

@font-face {
  font-family: 'Newake';
  src: url('./fonts/NewakeFont-Demo.otf');
};


  @keyframes slideInFromLeft {
    0% {
      transform: translateY(100%);
      opacity: 1%;
    }
    50% {
      opacity: 25%;
    }
    100% {
      transform: translateY(0);
      opacity: 100%;
    }
  }

    @keyframes fadeIn {
     
      50%{
        visibility: hidden;
        opacity: 1%;
      }
      100% {
        visibility: visible;
        opacity: 100%;
      }
    }
  
    @media (max-width: 768px) {
      .main {
        margin-right: 0%;
        margin-left: 2%;
      }
  }

  @media (max-width: 768px) {
    .font-face-ModernSans {

      font-family: "Verdana";
      color: #b6a59a;
      font-size: 18px;
      font-weight: 100;
        animation: 2s fadeIn;
        animation-fill-mode: forwards;
        visibility: hidden;
      
      margin-top: 80px;
      margin-bottom: 15px;
    }

    .spanPosEven{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;  
      margin-top: 60px;
    }

    .spanPosOdd{
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;  
      margin-top: 60px;
    }
}


@media (max-width: 768px) {
.spanAbout{
  display: flex;
  flex-direction: row;
  align-items: center;
  
}

.span3{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-right: 5%;
}

.cardwork {
  background-color:#454545;
  border-radius:20px;
  width:90%;
  margin-top:20px;
  padding: 2% 1%;
  transition: transform .2s; /* Animation */
  box-shadow: 0px 5px 15px rgba(0,0,0,0.25);
  margin-left: 3%;
}

.text{
 
  color:rgba(194,194,194,0.7);
}

.appleimage {
  border-radius: 10px;
  margin-inline: 3%!important;
  height: 150px;
  width: 170px;
}

.wewe {
  display: flex;
  justify-content: space-between;
}


.posCard {
    width: 80%;
    border-radius: 20px;
  }

  .textAlignment{
  
    align-items:'center'
  }
  
  .text{
    margin-top:20px;
    font-size:15px;
    font-family:Verdana;
    color:rgba(194,194,194,0.7);
    margin-bottom: 20px;
    width: 90vw;
  }
  
  .textTitle{
    font-size:15px;
    font-family:Verdana;
    color:rgba(194,194,194,0.7);
    margin-bottom: 10px;
    font-weight: bold;
    width: 80%;
  }

  

}































@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.gradient {
 /*  --size: 250px;
  --speed: 50s;
  --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);

  width: var(--size);
  height: var(--size); */
  background-color: #121212; }
/*   background-image: linear-gradient(hsl(158, 82, 57, 85%), hsl(252, 82, 57));
  animation: rotate var(--speed) var(--easing) alternate infinite;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%; 
}

@media (min-width: 720px) {
  .gradient {
    --size: 500px;
  }
}

/* body {
  background-color: #222;
  inset: 0;
  display: flex;
  place-content: center;
  align-items: center;
  overflow: hidden;
}

* {
  transition: all 0.25s ease-out;
} */


